
* {
    margin: 0px;
    padding:0px;
}

@media only screen and (min-width: 1600px) {
    .main{
        padding-left:100px;
        padding-right:100px;
    }
} 

input[type="file"] {
    display: none;
}

.upload-btn {
    width: 0px;
    height: 0px !important;
    padding:19px !important;
}

.fill-space{
    flex: 1 1 auto;
}

.main{
    margin:20px;
}

.top-menu button, .upload-btn  {
    margin:10px; 
}

#graph-panel {
    max-height: 800px;
    max-width: 100%;
}


#canvas-panel-block {
    overflow: scroll;
    max-height: 670px;
    max-width: 100%;
}

#container {
    min-width: 5000px;
    height: 1500px;
    margin-top: 900px;
}

.nodeInput{
    text-align: center;
    background-color: transparent;
    border:none;
}

.nodeInput:focus{
    outline: none;
}

#result-table {
    width: 100%;
    td:first-child, th:first-child{
        font-family: 'Cambria';
        font-size: 18px;
        font-style: italic;
        // font-weight: bold;
    }
}

.lca-iterations{
    text-align: center !important;
}

.lca-header{
    font-style: italic;
    text-align: center !important;
}

.lca-values{
    text-align: center !important;
}

.lca-q{
    text-align: center !important;
}

.pc-font {
    font-family: 'Courier New', Courier, monospace;
    background: rgb(224, 224, 224);
    // background: hsl(204, 86%, 53%);
    // color:white;
}

.info-table {
    width: 100%;
}

.panel.is-info .panel-heading {
    background-color: #cc3333 !important;
}

.button.is-info.is-outlined {
    border-color: #cc3333 !important;
    color: #cc3333 !important;
    background-color: #ffffff !important;
}

.button.is-info.is-outlined:hover {
    background-color: #cc3333 !important;
    color: #ffffff !important;
}

.header {
    margin-top: 15px;
    padding-left: 100px;
    padding-right: 100px;
}

.panel-heading {
    background-color: #ffffff !important;
}

.header_row {
    display: flex;
}

.logo {
    width: 150px;
}

.col_header_1 {
    width: 25%;
    text-align: center;
    vertical-align: middle;
}

.col_header_2 {
    width: 75%;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
}

.tile.is-3 {
max-height: 1100px;
}

.result-tile-heading {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 10px;
    font-size: 22px;
    font-weight: bold;
}

.user-preferences {
    font-weight: bold;
    margin-bottom: 20px;
    margin-left: 10px;
}

//------------------------------------------------------------------------------------------------------
/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content/Box */
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
  }
  
  /* The Close Button */
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .PRBM-close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .PRBM-close:hover,
  .PRBM-close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .numberInput {
      width: 55px;
  }

  .welcomeModal {
    position: absolute;
    top:0;
    left:0;
    height: 100%;
    width: 100%;

    z-index:101;
    background-color: #ffffff;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s, visibility 0s 0.5s;
  }

  .welcomeModal.active {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s;
    
}

.modal-button-section {
    margin-top: 30px;
    text-align: center;
}

.logo-section-modal {
    margin-top: 5%;
    margin-bottom: 30px;
    text-align: center;
}

.logo-modal {
    width: 250px;
}

.modal-h {
    text-align: center;
    margin-bottom: 30px;
}

.modal-basic-text {
    text-align: center;
    margin-bottom: 60px;
}

.center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .hideScrollbar {
    height: 100vh;
    overflow-y: hidden;
  }